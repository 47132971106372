.textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

.cAl {
    font-size: 28px;
    font-weight: 100;
    margin: 0;
    color: #444;
}

.nameOfPalace {
    font-size: 20px;
}

.logo {
    width: 100%;
    max-width: 200px;
    margin: 2rem 0;
}

.links {
    font-size: 20px;
    font-weight: 100;
    color: #444;
    text-decoration: none;
    margin: 0.5rem 0;
}

.email {
    color: #87CFF0;
}

.locationImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 3rem;
}

@media screen and (min-width: 760px) {
    .cAl {
        font-size: 40px;
    }
    .nameOfPalace {
        font-size: 25px;
    }
}

@media (min-width: 1300px) {
    .cAl {
        font-size: 43px;
        font-weight: 200;
        line-height: 1.4;
    }

    .nameOfPalace {
        font-size: 25px;
    }

    .links {
        font-size: 16px;
    }

    .locationImage {
        max-height: 70vh;
    }
}