.modal {
  position: fixed;
  z-index: 150;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal img {
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 30px;
  cursor: pointer;
  color: #fff;
}

.navigation {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.prev,
.next {
  font-size: 40px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: rgba(30, 30, 30, 0.8);
  color: rgb(220, 220, 220);
  border-radius: 5px;
  border-color: transparent;
  display: flex; /* Add this property */
  align-items: center; /* Add this property */
  justify-content: center; /* Add this property */
}

.prev {
  left: 25px;
}

.next {
  right: 25px;
}


@media (max-width: 768px) {
  .prev,.next {
    font-size: 20px;
    text-align: center;
  }
}
