.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.oops {
    font-size: 3.75rem;
    font-weight: 700;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.lostText {
    font-size: 1.3rem;
    margin: 0;
}

.notFoundImage {
    margin: 2.5rem 0;
    width: 120%;
}

.goBack {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
}

.goHomeText {
    font-size: 1.3rem;
    margin: 0;
    margin-left: 0.5rem;
}

@media screen and (max-width: 1024px) {
    .oops {
        font-size: 2.5rem;
    }

    .lostText {
        font-size: 1rem;
    }

    .notFoundImage {
        width: 150%;
    }

    .goBack {
        font-size: 1rem;
    }
}