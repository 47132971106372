.navbarContainer {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.7rem;
}

.navMenu {
    position: absolute;
    flex-direction: column;
    top: 2.5rem;
    left: -100%;
    width: 100%;
    transition: 0.7s ease-in-out;
    list-style-type: none;
    text-align: center;
    padding-left: 0;
    margin-top: 0.5rem;
}

.active{
    left: 0;
    background-color: white;
}

.navMenu li {
    margin: 16px 0;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: 0.5rem;
}

.navLink {
    text-decoration: none;
    color: #333;
    font-size: 1rem;
    letter-spacing: 0.5px;
}

.circle, .circle2, .desktopLogo {
  display: none;
}

.navMenuDesktop {
  display: none;
}

.cover {
  display: block;
  width: 100%;
  height: 100%; /*maybe change to 100vh*/
  object-fit: cover;
}

.ctaMobile {
  width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 2px 4px;
    z-index: 120;
    display: flex;
    align-content: center;
    justify-content: center;
}

.item {
  width: calc(50% - 4px);
  margin: 0 1rem;
}

.button {
  background: #87cff0;
  color: #fff;
  text-align: center;
  display: block;
  padding: 10px 0;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.1ch;
  width: 100%;
  box-shadow: 0 -3px 7px rgba(0,0,0,.1), inset 0 -10px 20px rgba(0,0,0,.04);
  border: none;
  cursor: pointer;
}

.contactInfoMobile ul {
  position: fixed;
  bottom: 65px;
  right: -70px;
  width: 120px;
  height: auto;
  z-index: 30;
  background-color: transparent;
  padding: 10px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  display: none;
}

.contactInfoMobile li {
  width: 40px;
  height: 40px;
  background-color: #f5f5f5;
  border-radius: 50%;
  padding: 10px;
  margin-top: 20px;
  box-shadow: 1px 1px 2px rgba(0,0,0,.2);
  position: relative;
  padding: 0;
}

.contactIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.ctaLink {
  text-decoration: none;
  color: #fff;
}

@media screen and (max-width: 760px) {
  .contactInfoMobile ul {
    display: block;
  }
}

@media screen and (min-width:1300px){
  .hamburger {
    display: none;
  }

  .mobileLogo {
    display: none;
  }

  .desktopLogo {
    display: block;
  }

  .navbarContainer {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    width: 99%;
    z-index: 111;
  }

  .navMenuDesktop {
    display: flex;
    align-items: center;
    text-decoration: none;
    list-style-type: none;
    color: white;
  }

  .navMenuDesktop li {
    margin: 0 2.5rem;
  }

  .navLink {
    color: white;
    font-family: Montserrat, 'sans-serif';  
  }

  .navLink:hover {
    border-bottom: 1px solid white;
  }

  .circle {
    display: block;
    width: 100px; /* Adjust the width and height as needed */
    height: 100px;
    border: 2px solid #87cff0;
    border-radius: 50%; /* Set border-radius to 50% to create a circle */
    text-align: center; /* Center the content horizontally */
    line-height: 85px; /* Center the content vertically */
    position: relative;
    margin: 0.5rem 4.4rem;
  }
  
  .circle:hover {
    box-shadow: 0 0 10px 5px rgba(238, 231, 231, 0.1); /* Add a shadow on hover */
  }
  
  .circle2 {
    display: block;
    width: 92px; /* Adjust the size of the inner circle as needed */
    height: 92px;
    border: 2px solid black;
    background-color: white;
    border-radius: 50%;
    position: absolute; /* Position the inner circle relative to the outer circle */
    top: 50%; /* Center the inner circle vertically */
    left: 50%; /* Center the inner circle horizontally */
    transform: translate(-50%, -50%); /* Center the inner circle accurately */
  }

  .navRoundLink {
    text-decoration: none;
    color: #333;
  }

  .ctaMobile, .contactInfoMobile {
    display: none;
  }

  .cover {
    height: 100vh;
  }
}