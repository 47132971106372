.textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    color: #444;
}

.heading {
    font-size: 28px;
    font-weight: 100;
    margin: 0;
}

.text {
    margin-left: 3rem;
    margin-right: 3rem;
    text-align: center;
    font-weight: 100;
}

.locationImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 3rem;
}

@media screen and (min-width: 1300px) {
    .locationImage {
        max-height: 70vh;
    }
}