.textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

.text {
    font-size: 28px;
    font-weight: 100;
    margin: 0;
    color: #444;
}

.nameOfPalace {
    font-size: 20px;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

.image:hover {
    cursor: pointer;
    opacity: 0.8;
}

.roomDetailsWrapper {
    display: flex;
    flex-direction: column;
    margin: 2rem;
}

.name {
    font-size: 30px;
    font-weight: 100;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 0;
}

.description {
    max-width: 70ch;
    font-weight: 100;
    font-size: 15px;
    margin-top: 0.5rem;
}

.button {
    background-color: #87cff0;
    border: 1px solid #87cff0;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: .1ch;
    margin: 0;
    padding: 7px 25px;
    width: 100%;
    text-transform: uppercase;
}

.buttonLink:hover {
    color: #87cff0;
    opacity: 0.8;
}

.button:hover {
    cursor: pointer;
}

@media screen and (min-width: 760px) {
    .text {
        font-size: 40px;
    }
    .nameOfPalace {
        font-size: 25px;
    }

    .image {
        max-height: 370px;
    }
}

@media screen and (min-width: 1300px) {
    .text {
        font-size: 40px;
    }

    .nameOfPalace {
        font-size: 25px;
    }
    
    .roomsWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        margin: 4rem 1.5rem 0 1.5rem;
    }
    .name {
        font-size: 40px;
    }
    
    .description {
        max-width: 50ch;
        font-size: 18px;
    }
    
    .buttonLink {
        max-width: 180px;
    }
    
    .button {
        font-size: 16px;
        padding: 10px 30px;
        max-width: 180px;
    }
}
    