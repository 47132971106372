.headingAndDescriptionWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 3rem 2rem;
}

.text {
    font-size: 28px;
    font-weight: 100;
    margin: 0;
    color: #444;
}

.nameOfPalace {
    font-size: 20px;
}

.photosWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 1rem;
}

.photoWrapper {
    flex-basis: 33%;
    padding: 0 1px;
    box-sizing: border-box;
    overflow: hidden;
    max-width: 200px;
    max-height: 100px;
  }
  
.image {
    width: 100%;
    height: 99%;
    object-fit: cover;
    cursor: pointer;
}

@media screen and (min-width: 760px) {
    .text {
        font-size: 40px;
    }
    .nameOfPalace {
        font-size: 25px;
    }

    .photoWrapper {
        flex-basis: 25%; /* 25% width for 4 photos per row */
        min-height: 345px;
        max-width: 345px;
        max-height: 345px;
        padding: 0 3px;
    }
}

@media screen and (min-width:1300px) {
    .nameOfPalace {
        font-size: 25px;
    }

    .photosWrapper {
        margin: 0 4rem;
    }

    .image {
        transition: transform 0.5s;
    }

    .photoWrapper:hover img {
        transform: scale(1.2);
    }
}