.formContainer {
    border: black 1px solid;
    box-shadow: 0 0 5px #444;
    margin: 2rem;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #444;
    background-color: #FCFCFC;
    font-weight: 100;
}

.info {
    font-size: 20px;
    font-weight: 100;
}

.availability {
    text-align: center;
    font-size: 16px;
}

.button {
    font-size: 14px;
    margin: 0 auto;
    padding: 12px 25px;
    background-color: #fff;
    border: 1px solid #444;
    font-weight: 100;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.calendar {
    margin-right: 0.5rem;
}

.link {
    text-decoration: none;
    color: #444;
    margin: 0;
    font-weight: 400;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.input {
    font-size: 14px;
    border: 0 solid #333;
    border-bottom: 1px solid #333;
    margin-bottom: 16px;
    height: 30px;
    outline: none;
    transition: all 0.1s linear;
    padding: 9px;
    background-color: transparent;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textArea {
    height: 120px;
    border: 1px solid #333;
    padding: 9px;
    margin-bottom: 1.5rem;
}

.email {
    margin-right: 0.5rem;
}

@media (min-width: 1300px) {
    .formContainer {
        width: 100%;
        max-width: 1040px;
    }

    .info {
        font-size: 43px;
        line-height: 1.4em;
        font-weight: 200;
        margin-bottom: 1rem;
    }

    .input {
        width: -webkit-fill-available;
    }

    .inputContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: -webkit-fill-available;
        grid-column-gap: 3rem;
    }

    .textArea {
        width: -webkit-fill-available;
    }
}