.footerContainer {
    background-color: #efefef;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    padding-bottom: 3.5rem;
}

.text {
    font-size: 16px;
    font-weight: 100;
    margin: 0 2rem 1rem 2rem;
    color: #444;
}

@media screen and (min-width:1300px) {

    .footerContainer {
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem 4rem;
    }

    .text {
        margin: 0;
    }
    
}