.headingAndDescriptionWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 3rem 2rem;
}

.text {
    font-size: 28px;
    font-weight: 100;
    margin: 0;
    color: #444;
    text-align: center;
}

.nameOfPalace {
    font-size: 20px;
}

.longDescription {
    font-size: 16px;
    color:#444;
    font-weight: 100;
    text-align: center;
    margin-top: 3rem;
}

.title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 30px;
    color: #444;
    margin-bottom: 1rem;
}

.roomSpecs {
    font-size: 18px;
    color:#444;
    font-weight: 100;
    margin: 0;
}

.list {
    list-style-type: square;
}

.feature {
    font-size: 16px;
    color:#444;
    font-weight: 100;
}

.specWrapper {
    margin: 0 2rem;
}

.button {
    background-color: #87cff0;
    border: 1px solid #87cff0;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: .1ch;
    margin-bottom: 2rem;
    padding: 7px 25px;
    width: 100%;
    text-transform: uppercase;
}

.buttonLink:hover {
    color: #87cff0;
    opacity: 0.8;
}

.button:hover {
    cursor: pointer;
}

.buttonWrapper {
    margin: 0 2rem;
}

.exploreText {
    font-size: 20px;
    color:#444;
    font-weight: 100;
    text-align: center;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (min-width: 760px) {
    .text {
        font-size: 40px;
    }

    .nameOfPalace {
        font-size: 25px;
    }

    .longDescription {
        margin-left: 5rem;
        margin-right: 5rem;
    }
}

@media screen and (min-width:1300px) {
    .nameOfPalace {
        font-size: 25px;
    }

    .specWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 5rem 10rem;
    }

    .featuresWrapper {
        columns: 2;
    }

    .title {
        font-size: 20px;
    }

    .buttonWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
    }

    .button {
        max-width: 250px;
    }

    .exploreText {
        font-size: 45px;
        margin-bottom: 1.5rem;
    }

    .imageWrapper {
        margin-left: 5rem;
        margin-right: 5rem;
    }
}