.textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

.text {
    font-size: 28px;
    font-weight: 100;
    margin: 0;
    color: #444;
}

.description {
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top: 3rem;
    text-align: center;
    font-weight: 100;
}

.imagesWrapper {
    display: flex;
    flex-direction: column;
    margin: 2rem;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 1rem;
}

@media screen and (min-width: 760px) {
    .text {
        font-size: 40px;
        font-weight: 100;
    }

    .nameOfPalace {
        font-size: 25px;
    }

    .imagesWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        margin: 4rem;
    }
}